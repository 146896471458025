import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import "moment/locale/id";
import domain from "../util/domain";
import Alert from "../util/alert";
import Loading from "../common/loading.component";

//import WaitingButton from "../common/waitingbutton.component";
//import DatePicker, { registerLocale } from "react-datepicker";
//import id from "date-fns/locale/id";
//import Loading from "../common/loading.component";
//registerLocale("id", id);

export default function Inpatient() {
    let { mrn, id } = useParams();

    const [patient, setPatient] = useState();
    const [inpatient, setInpatient] = useState();
    const [notification, setNotification] = useState();

    useEffect(() => {
        async function fetchData() {
            await axios
                .get(`${domain}/patients/` + mrn + "/" + id + "/inpatient")
                .then((response) => {
                    setPatient(response.data.patient);
                    setInpatient(response.data.inpatient);
                })
                .catch((error) => {
                    if (!error.response) {
                        // network error
                        setNotification({
                            message: "Exception: " + error,
                            type: "danger"
                        });
                    } else {
                        //setError(error.response.data.msg);
                        setNotification({
                            message: error.response.data.msg,
                            type: "danger"
                        });
                    }
                });
        }
        fetchData();
    }, [mrn, id]);

    return (
        <div className="mt-3">
            {notification ? (
                <Alert message={notification.message} type={notification.type} />
            ) : patient ? (
                <div>
                    <h5 className="text-center">Data Rawat Inap</h5>
                    <table className="table table-sm">
                        <tbody>
                            <tr>
                                <th>MRN</th>
                                <td>{patient.mrn}</td>
                            </tr>
                            <tr>
                                <th>Nama</th>
                                <td>{patient.fullName}</td>
                            </tr>
                            <tr>
                                <th>Tgl. Keluar</th>
                                <td>{moment(inpatient.dischargeDate).format("dddd, DD-MM-YYYY")}</td>
                            </tr>
                            <tr>
                                <th>DPJP</th>
                                <td>{inpatient.dpjp1}</td>
                            </tr>
                            <tr className="table-primary text-center">
                                <th colSpan={2}>Terverifikasi</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <Loading />
            )}
        </div>
    );
}
