import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import "moment/locale/id";
import domain from "../util/domain";
import Alert from "../util/alert";
import Loading from "../common/loading.component";

export default function IDCard() {
    let { mrn, id } = useParams();

    const [patient, setPatient] = useState();
    const [notification, setNotification] = useState();

    useEffect(() => {
        async function fetchData() {
            await axios
                .get(`${domain}/patients/` + mrn + "/" + id + "/idcard")
                .then((response) => {
                    setPatient(response.data.patient);
                })
                .catch((error) => {
                    if (!error.response) {
                        // network error
                        setNotification({
                            message: "Exception: " + error,
                            type: "danger"
                        });
                    } else {
                        //setError(error.response.data.msg);
                        setNotification({
                            message: error.response.data.msg,
                            type: "danger"
                        });
                    }
                });
        }
        fetchData();
    }, [mrn, id]);

    return (
        <div>
            {notification ? (
                <Alert message={notification.message} type={notification.type} />
            ) : patient ? (
                <div className="mt-4">
                    <style type="text/css">{"#navbar {display: none !important}"}</style>
                    <style type="text/css">{"#sidebar-container {display: none !important}"}</style>
                    <table
                        style={{
                            fontFamily: "Georgia",
                            color: "black",
                            width: "300",
                            marginLeft: "25"
                        }}
                    >
                        <tr>
                            <td style={{ paddingRight: "25px" }}>
                                <img src="/img/LogoRSCS-Black.png" alt="" width="250" />
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <table>
                                    <tr>
                                        <td style={{ paddingRight: "25px" }}>
                                            <img src="/img/PatientCardBg.png" alt="" width="180" />
                                        </td>
                                        <td>
                                            <table style={{ width: "100%" }}>
                                                <tr>
                                                    <td>
                                                        <h5>Patient Card</h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{patient.mrn}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {patient.fullName} / {patient.sex[0]}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{moment(patient.dateOfBirth).format("DD-MM-YYYY")}</td>
                                                </tr>
                                                {patient.insuranceID && (
                                                    <tr>
                                                        <td>{patient.insuranceID}</td>
                                                    </tr>
                                                )}
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
            ) : (
                <Loading />
            )}
        </div>
    );
}
