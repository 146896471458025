export default function containsFloatRef(results) {
    var refValues;
    var ret = false;
    for (var i in results) {
        if (isFloat(results[i].value)) return true;
        refValues = results[i].refValues;
        if (!refValues || refValues.length === 0) ret = false;
        else if (refValues.length === 1) {
            if (refValues[0].normal) return isFloat(refValues[0].normal);
            else {
                ret = isFloat(refValues[0].low) || isFloat(refValues[0].high);
            }
        } else if (refValues.length === 2) {
            ret = isFloat(refValues[0].low) || isFloat(refValues[0].high) || isFloat(refValues[1].low) || isFloat(refValues[1].high);
        } else if (refValues.length === 5) {
            ret =
                isFloat(refValues[0].low) ||
                isFloat(refValues[0].high) ||
                isFloat(refValues[1].low) ||
                isFloat(refValues[1].high) ||
                isFloat(refValues[2].low) ||
                isFloat(refValues[2].high) ||
                isFloat(refValues[3].low) ||
                isFloat(refValues[3].high) ||
                isFloat(refValues[4].low) ||
                isFloat(refValues[4].high);
        }
        if (ret) break;
    }
    return ret;
}

function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
}