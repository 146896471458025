import Router from "./Router";
import "./App.css";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

function App() {
    return <Router />;
}

export default App;
