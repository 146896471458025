export default function getSalutation(fullName, sex, dateOfBirth, maritalStatus) {
    var todate = new Date();

    var fromdate = new Date(dateOfBirth),
        y = [todate.getFullYear(), fromdate.getFullYear()],
        ydiff = y[0] - y[1],
        m = [todate.getMonth(), fromdate.getMonth()],
        mdiff = m[0] - m[1],
        d = [todate.getDate(), fromdate.getDate()],
        ddiff = d[0] - d[1];

    if (mdiff < 0 || (mdiff === 0 && ddiff < 0)) --ydiff;

    if (ydiff < 2) {
        if (fullName.toLowerCase().startsWith("by")) return "";
        else return "An. ";
    }
    if (ydiff < 18) {
        if (maritalStatus === "Belum Menikah") return "An. ";
        else if (sex === "Laki-laki") return "Tn. ";
        return "Ny. ";
    }
    if (sex === "Laki-laki") {
        if (maritalStatus === "Belum Menikah") return "Sdr. ";
        else return "Tn. ";
    } else {
        if (maritalStatus === "Belum Menikah") return "Nn. ";
        else return "Ny. ";
    }
}
