import React from "react";
import { Text, View, Image } from "@react-pdf/renderer";

export default function Header() {
    return (
        <View style={{ flexDirection: "col", marginTop: 10 }}>
            <View style={{ flexDirection: "row" }}>
                <View style={{ flexDirection: "col", textAlign: "left", width: "50%" }}>
                    <Image style={{ height: 50, width: 185 }} src="/img/LogoRSCS-Black.png" />
                </View>
                <View style={{ flexDirection: "col", textAlign: "right", width: "50%" }}>
                    <Text>Jl. Temu Putih 100, Cilegon</Text>
                    <Text>(0254) 396710</Text>
                    <Text>rscitrasundari.com</Text>
                </View>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center", paddingBottom: 10, marginTop: 10 }}>
                <View style={{ flex: 1, height: 1, backgroundColor: "black" }} />
            </View>
        </View>
    );
}

/*
return (
        <div>
            <PDFDownloadLink document={<MyDocument />} fileName="somename.pdf">
                {({ blob, url, loading, error }) => (loading ? "Loading document..." : "Download now!")}
            </PDFDownloadLink>
        </div>
    );


    <View style={{ flexDirection: "row", alignItems: "center" }}>
                <View style={{ flex: 1, height: 1, backgroundColor: "black" }} />
                <View>
                    <Text style={{ width: 50, textAlign: "center" }}>Hello</Text>
                </View>
                <View style={{ flex: 1, height: 1, backgroundColor: "black" }} />
            </View>
    */
