import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import "moment/locale/id";
import domain from "../util/domain";
import Alert from "../util/alert";
import Loading from "../common/loading.component";
import MCUPdf from "./pdf/mcu.component";
import { QRCode } from "react-qrcode-logo";
import LabResultPdf from "./pdf/lab.component";
import RadiologyResultPdf from "./pdf/radiology.component";
import getNotes from "./misc/getNotes";

export default function MCU() {
    let { mrn, id } = useParams();

    const [patient, setPatient] = useState();
    const [mcu, setMCU] = useState();
    const [notification, setNotification] = useState();
    const [qrCodeDataUri, setQRCodeDataUri] = useState();

    useEffect(() => {
        async function fetchData() {
            await axios
                .get(`${domain}/patients/` + mrn + "/" + id + "/mcu")
                .then((response) => {
                    setPatient(response.data.patient);
                    setMCU(response.data.mcu);

                    var delayInMilliseconds = 500; //1 second
                    setTimeout(function () {
                        //your code to be executed after 1 second
                        setQRCodeDataUri(document.querySelector("canvas").toDataURL("image/jpg", 0.3));
                    }, delayInMilliseconds);
                })
                .catch((error) => {
                    if (!error.response) {
                        // network error
                        setNotification({
                            message: "Exception: " + error,
                            type: "danger"
                        });
                    } else {
                        //setError(error.response.data.msg);
                        setNotification({
                            message: error.response.data.msg,
                            type: "danger"
                        });
                    }
                });
        }
        fetchData();
    }, [mrn, id]);

    const getIndicators = (lab) => {
        var values = [];
        var indicators = [];
        for (var i in lab.results) {
            values.push(lab.results[i].value);
            indicators.push(lab.results[i].indicator);
        }
        return indicators;
    };

    const authorizedBy = (lab) => {
        return lab.performedBy.indexOf("A.Md.A.K") > 0 || lab.performedBy.indexOf("A.Md.Kes") > 0 ? lab.performedBy : "Alfi Anshori, A.Md.A.K";
    };

    return (
        <div className="mt-3">
            {notification ? (
                <Alert message={notification.message} type={notification.type} />
            ) : patient ? (
                <div>
                    <h5 className="text-center">Data MCU</h5>
                    <table className="table table-sm">
                        <tbody>
                            <tr>
                                <th>MRN</th>
                                <td>{patient.mrn}</td>
                            </tr>
                            <tr>
                                <th>Nama</th>
                                <td>{patient.fullName}</td>
                            </tr>
                            <tr>
                                <th>Tgl. Pemeriksaan</th>
                                <td>{moment(mcu.createdAt).format("dddd, DD-MM-YYYY")}</td>
                            </tr>
                            <tr>
                                <th>Dokter</th>
                                <td>{mcu.performedBy}</td>
                            </tr>
                            <tr style={{ visibility: "hidden", display: "none" }}>
                                <td colSpan={2}>
                                    <div id="canvas">
                                        <QRCode
                                            value={"https://archive.rscitrasundari.com/mcu/" + patient.mrn + "/" + mcu._id}
                                            size={95}
                                            qrStyle="dots"
                                            removeQrCodeBehindLogo={true}
                                            logoImage="/img/Logo.png"
                                            logoWidth={20}
                                        />
                                    </div>
                                </td>
                            </tr>
                            {mcu.patient && (
                                <>
                                    <tr>
                                        <td colSpan={2} className="text-center">
                                            <MCUPdf patient={patient} data={mcu} qrImage={qrCodeDataUri} />
                                        </td>
                                    </tr>
                                    {patient.labHistory
                                        .filter((item) => {
                                            return new Date(item.createdAt).getTime() >= new Date(mcu.requestedAt).getTime() && new Date(item.createdAt).getTime() <= new Date(mcu.updatedAt).getTime();
                                        })
                                        .map((lab) => (
                                            <tr>
                                                <td colSpan={2} className="text-center">
                                                    <LabResultPdf
                                                        patient={patient}
                                                        labResultData={lab}
                                                        indicators={getIndicators(lab)}
                                                        authorizedBy={authorizedBy(lab)}
                                                        notes={getNotes(lab)}
                                                        qrImage={qrCodeDataUri}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    {qrCodeDataUri && patient.radiologyHistory
                                        .filter((item) => {
                                            return new Date(item.createdAt).getTime() >= new Date(mcu.requestedAt).getTime() && new Date(item.createdAt).getTime() <= new Date(mcu.updatedAt).getTime();
                                        })
                                        .map((radiology) => (
                                            <tr>
                                                <td colSpan={2} className="text-center">
                                                    <RadiologyResultPdf patient={patient} radiologyResultData={radiology} qrImage={qrCodeDataUri} />
                                                </td>
                                            </tr>
                                        ))}
                                </>
                            )}
                            <tr className="table-primary text-center">
                                <th colSpan={2}>Terverifikasi</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <Loading />
            )}
        </div>
    );
}
