export default function getAge(frmdate, todate){
    if(todate) todate = new Date(todate);
    else todate = new Date();

    var age= [], fromdate = new Date(frmdate),
    y= [todate.getFullYear(), fromdate.getFullYear()],
    ydiff= y[0]-y[1],
    m= [todate.getMonth(), fromdate.getMonth()],
    mdiff= m[0]-m[1],
    d= [todate.getDate(), fromdate.getDate()],
    ddiff= d[0]-d[1];

    /*
    console.log(fromdate.getFullYear() + " " + fromdate.getMonth() + " " + fromdate.getDate() + " from");
    console.log(todate.getFullYear() + " " + todate.getMonth() + " " + todate.getDate() + " todate");
    console.log(ddiff + " " + mdiff + " " + ydiff + " diff");
    */
   
    if(mdiff < 0 || (mdiff=== 0 && ddiff<0))--ydiff;
    
    if(ddiff<0){
        fromdate.setMonth(m[1]+1, 0);
        ddiff= fromdate.getDate()-d[1]+d[0];
        --mdiff;
    }
    if(mdiff<0) mdiff+= 12;
    /*
    if(ydiff> 0) age.push(ydiff+ ' year'+(ydiff> 1? 's ':' '));
    if(mdiff> 0) age.push(mdiff+ ' month'+(mdiff> 1? 's':''));
    if(ddiff> 0) age.push(ddiff+ ' day'+(ddiff> 1? 's':''));
    */
    if(ydiff> 0) age.push(ydiff+ ' tahun ');
    if(mdiff> 0) age.push(mdiff+ ' bulan ');
    if(ddiff> 0) age.push(ddiff+ ' hari');

    if(age.length>1) age.splice(age.length-1,0,' ');    
    return age.join('').trim();
}