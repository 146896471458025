import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import "moment/locale/id";
import domain from "../util/domain";
import Alert from "../util/alert";
import Loading from "../common/loading.component";
import getRoman from "./misc/getRoman";
import getZeroPad from "./misc/getZeroPad";

//import WaitingButton from "../common/waitingbutton.component";
//import DatePicker, { registerLocale } from "react-datepicker";
//import id from "date-fns/locale/id";
//import Loading from "../common/loading.component";
//registerLocale("id", id);

export default function Invoice() {
    let { mrn, id } = useParams();

    const [patient, setPatient] = useState();
    const [invoice, setInvoice] = useState();
    const [notification, setNotification] = useState();

    useEffect(() => {
        async function fetchData() {
            await axios
                .get(`${domain}/patients/` + mrn + "/" + id + "/invoice")
                .then((response) => {
                    setPatient(response.data.patient);
                    setInvoice(response.data.invoice);
                })
                .catch((error) => {
                    if (!error.response) {
                        // network error
                        setNotification({
                            message: "Exception: " + error,
                            type: "danger"
                        });
                    } else {
                        //setError(error.response.data.msg);
                        setNotification({
                            message: error.response.data.msg,
                            type: "danger"
                        });
                    }
                });
        }
        fetchData();
    }, [mrn, id]);

    return (
        <div className="mt-3">
            {notification ? (
                <Alert message={notification.message} type={notification.type} />
            ) : patient ? (
                <div>
                    <h5 className="text-center">{invoice.guarantor.name === "Umum" ? "Kwitansi" : "Invoice"}</h5>
                    <table className="table table-sm">
                        <tbody>
                            <tr>
                                <th>No</th>
                                <td>
                                    {invoice.ext_invoice_no
                                        ? getZeroPad(invoice.ext_invoice_no, 4) + "/INV/RSCS/" + getRoman(moment(invoice.createdAt).format("M")) + "/" + moment(invoice.createdAt).format("YYYY")
                                        : invoice.invoice_no}
                                </td>
                            </tr>
                            <tr>
                                <th>MRN</th>
                                <td>{patient.mrn}</td>
                            </tr>
                            <tr>
                                <th>Nama</th>
                                <td>{patient.fullName}</td>
                            </tr>
                            <tr>
                                <th>Tanggal</th>
                                <td>{moment(invoice.createdAt).format("dddd, DD-MM-YYYY")}</td>
                            </tr>
                            {invoice.guarantor.name !== "Umum" && invoice.guarantor.company && (
                                <tr>
                                    <th>Perusahaan</th>
                                    <td>{invoice.guarantor.company}</td>
                                </tr>
                            )}
                            <tr>
                                <th>DPJP</th>
                                <td>{invoice.doctor}</td>
                            </tr>
                            <tr className="table-primary text-center">
                                <th colSpan={2}>Terverifikasi</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <Loading />
            )}
        </div>
    );
}
