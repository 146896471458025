export default function getAgeInMonths(frmdate, todate) {
    if (todate) todate = new Date(todate);
    else todate = new Date();

    var fromdate = new Date(frmdate),
        y = [todate.getFullYear(), fromdate.getFullYear()],
        ydiff = y[0] - y[1],
        m = [todate.getMonth(), fromdate.getMonth()],
        mdiff = m[0] - m[1],
        d = [todate.getDate(), fromdate.getDate()],
        ddiff = d[0] - d[1];

    if (mdiff < 0 || (mdiff === 0 && ddiff < 0)) --ydiff;

    if (ddiff < 0) {
        fromdate.setMonth(m[1] + 1, 0);
        ddiff = fromdate.getDate() - d[1] + d[0];
        --mdiff;
    }
    if (mdiff < 0) mdiff += 12;

    var age = 0;
    if (ydiff > 0) age += ydiff * 12;
    if (mdiff > 0) age += mdiff;

    return age;
}
