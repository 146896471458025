import React from "react";
//import ReactDOM from "react-dom";
//import { PDFDownloadLink, PDFViewer, Page, Text, View, Document, StyleSheet, Font, Image } from "@react-pdf/renderer";
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Font, Image } from "@react-pdf/renderer";
import Header from "./header.component";
import moment from "moment";
import getAge from "../misc/getAge";
import getZeroPad from "../misc/getZeroPad";
import getSalutation from "../misc/getSalutation";

Font.register({
    family: "Segoe UI",
    fonts: [
        {
            src: "/fonts/Segoe UI/Segoe UI.ttf"
        },
        {
            src: "/fonts/Segoe UI/Segoe UI Bold.ttf",
            fontWeight: "bold"
        },
        {
            src: "/fonts/Segoe UI/Segoe UI Italic.ttf",
            fontWeight: "normal",
            fontStyle: "italic"
        }
    ]
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: "col",
        marginLeft: 30,
        marginTop: 10,
        marginRight: 20,
        fontFamily: "Segoe UI",
        fontSize: "9",
        paddingRight: 60
    },
    section: {
        padding: 10
    },
    tableHeader: {
        fontWeight: "bold",
        paddingBottom: 5
    },
    tableContent: {
        paddingBottom: 5
    }
});

// Create Document Component

const MyDocument = ({ patient, radiologyResultData, qrImage }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <Header />
            <View>
                <Text style={{ textAlign: "center", fontSize: "14" }}>Pemeriksaan Radiologi</Text>
            </View>
            <View style={styles.section}>
                <View style={{ flexDirection: "col" }}>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>No Pemeriksaan</Text>
                        <Text style={[styles.tableContent, { width: "80%" }]}>
                            {moment(radiologyResultData.createdAt).format("YYMMDD")}
                            {getZeroPad(radiologyResultData.rad_result_no, 3)}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>MRN</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{patient.mrn}</Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Sampel diterima</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{radiologyResultData.sampleReceivedAt && moment(radiologyResultData.sampleReceivedAt).format("DD-MM-YYYY HH:mm")}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Nama Pasien</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {getSalutation(patient.fullName, patient.sex, patient.dateOfBirth, patient.maritalStatus)}
                            {patient.fullName}
                        </Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Tanggal Selesai</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{moment(radiologyResultData.completedAt).format("DD-MM-YYYY HH:mm")}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Jenis Kelamin</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{patient.sex}</Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Tanggal Lahir / Usia</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {moment(patient.dateOfBirth).format("DD-MM-YYYY")} / {getAge(patient.dateOfBirth, new Date())}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Dokter</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{radiologyResultData.doctor ? radiologyResultData.doctor : radiologyResultData.requestedBy}</Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Ruang / Alamat</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{radiologyResultData.from}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Jenis Pemeriksaan</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{radiologyResultData.name}</Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Penjamin</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {radiologyResultData.guarantor.name}
                            {radiologyResultData.guarantor.name !== "Umum" && " - " + radiologyResultData.guarantor.company}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Keterangan Klinis</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{radiologyResultData.clinicalDescription}</Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Diagnosa</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{radiologyResultData.diagnosis}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Expertise</Text>
                        <Text style={[styles.tableContent, { width: "80%" }]}>{radiologyResultData.result}</Text>
                    </View>
                </View>
                <View style={{ flexDirection: "row", marginTop: 10 }}>
                    <View style={{ flexDirection: "col", width: "30%" }}>
                        <Image source={qrImage} style={{ height: 75, width: 75 }} />
                    </View>
                    <View style={{ flexDirection: "col", width: "40%", alignItems: "center" }}></View>
                    <View style={{ flexDirection: "col", width: "30%", alignItems: "right" }}>
                        <Text style={{ textAlign: "right", paddingBottom: 5 }}>Cilegon, {moment(new Date()).format("DD-MM-YYYY")}</Text>
                        <Text style={{ textAlign: "right", paddingBottom: 5 }}>Radiologi</Text>
                        <Image style={{ height: 40, width: "auto", marginLeft: "auto" }} src={"/img/" + radiologyResultData.specialist + ".png"} />
                        <Text style={{ textAlign: "right", paddingBottom: 5 }}>{radiologyResultData.specialist}</Text>
                    </View>
                </View>
            </View>
        </Page>
    </Document>
);

export default function Radiology({ patient, radiologyResultData, indicators, qrImage }) {
    return (
        <PDFDownloadLink
            className="btn btn-sm btn-outline-info"
            document={<MyDocument patient={patient} radiologyResultData={radiologyResultData} indicators={indicators} qrImage={qrImage} />}
            fileName={moment(radiologyResultData.completedAt).format("YYMMDD") + getZeroPad(radiologyResultData.rad_result_no, 3) + " - " + patient.mrn + " - " + radiologyResultData.name + ".pdf"}
        >
            {({ blob, url, loading, error }) => (loading ? "Loading ..." : "Download PDF - " + radiologyResultData.name)}
        </PDFDownloadLink>
    );
}
