import React from "react";
import moment from "moment";
import "moment/locale/id";
import getRoman from "../misc/getRoman";
import getZeroPad from "../misc/getZeroPad";

export default function Sick({ patient, letter }) {
    return (
        <div>
            <h5 className="text-center">Surat Keterangan Sakit</h5>
            <table className="table table-sm">
                <tbody>
                    <tr>
                        <th>No</th>
                        <td>
                            {letter.letter_no
                                ? getZeroPad(letter.letter_no, 3) + "/SKSK/RSCS/" + getRoman(moment(letter.createdAt).format("M")) + "/" + moment(letter.createdAt).format("YYYY")
                                : letter.letter_no}
                        </td>
                    </tr>
                    <tr>
                        <th>Nama</th>
                        <td>{patient.fullName}</td>
                    </tr>
                    <tr>
                        <th>Tanggal</th>
                        <td>{moment(letter.createdAt).format("dddd, DD-MM-YYYY")}</td>
                    </tr>
                    {letter.createdBy.indexOf("dr.") > -1 && (
                        <tr>
                            <th>Dokter</th>
                            <td>{letter.createdBy}</td>
                        </tr>
                    )}
                    <tr>
                        <th>Diagnosa</th>
                        <td>{letter.diagnosis}</td>
                    </tr>
                    <tr>
                        <th>Mulai dari</th>
                        <td>{moment(letter.startDate).format("dddd, DD-MM-YYYY")}</td>
                    </tr>
                    <tr>
                        <th>Sampai dengan</th>
                        <td>{moment(letter.endDate).format("dddd, DD-MM-YYYY")}</td>
                    </tr>
                    <tr className="table-primary text-center">
                        <th colSpan={2}>Terverifikasi</th>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
