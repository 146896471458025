export default function getAgeInYears(frmdate, todate){
    if(todate) todate = new Date(todate);
    else todate = new Date();

    var fromdate = new Date(frmdate),
    y= [todate.getFullYear(), fromdate.getFullYear()],
    ydiff= y[0]-y[1],
    m= [todate.getMonth(), fromdate.getMonth()],
    mdiff= m[0]-m[1],
    d= [todate.getDate(), fromdate.getDate()],
    ddiff= d[0]-d[1];
   
    if(mdiff < 0 || (mdiff=== 0 && ddiff<0))--ydiff;
    return ydiff;
}