import containsFloatRef from "./containsFloatRef";

export default function getNotes(data) {
    var aNotes = [];
    if (data.name.indexOf("Antigen SARS") === -1) {
        if (containsFloatRef(data.results)) {
            aNotes.push("Hasil berupa angka menggunakan sistem desimal dengan separator titik.");
        }
    }
    if (data.name.indexOf("Antigen SARS") >= 0 && data.results[0].value === "Negatif") {
        aNotes.push("Hasil negatif tidak menyingkirkan kemungkinan terinfeksi SARS-CoV-2, sehingga masih berisiko menularkan ke orang lain");
        aNotes.push("Hasil negatif dapat terjadi pada kondisi kuantitas antigen pada spesimen di bawah level deteksi alat rapid test");
        aNotes.push("Hasil akan tercatat pada aplikasi PeduliLindungi, atau bisa dicek melalui QR code di bawah");
    }

    if (data.name.indexOf("Antigen SARS") >= 0 && data.results[0].value === "Positif") {
        aNotes.push("Konfirmasi dengan pemeriksaan RT-qPCR dalam waktu 24-48 jam setelah hasil diterima");
        aNotes.push("Lakukan karantina atau isolasi sesuai dengan ketentuan");
        aNotes.push("Terapkan perilaku hidup bersih dan sehat dengan mencuci tangan, beretika batuk, menggunakan masker dan menjaga stamina");
        aNotes.push("Hasil akan tercatat pada aplikasi PeduliLindungi, atau bisa dicek melalui QR code di bawah");
    }

    if (data.notes) {
        aNotes.push(data.notes);
    }

    return aNotes;
}