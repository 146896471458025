import React from "react";

export default function Navbar() {


    return (
        <nav id="navbar" className="navbar navbar-dark bg-dark navbar-expand-lg">
            <div className="row container-fluid">
                <div className="col">
                    <a className="navbar-brand" href="/#">
                        <img src="/img/LogoRSCS.png" alt="" height="45" style={{ filter: "blur(0)", transform: "translateZ(0)" }} />
                    </a>
                </div>
            </div>
        </nav>
    );
}
