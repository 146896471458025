import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import "moment/locale/id";
import domain from "../util/domain";
import Alert from "../util/alert";
import Loading from "../common/loading.component";
import LabResultPdf from "./pdf/lab.component";
import { QRCode } from "react-qrcode-logo";
import getNotes from "./misc/getNotes";

//import WaitingButton from "../common/waitingbutton.component";
//import DatePicker, { registerLocale } from "react-datepicker";
//import id from "date-fns/locale/id";
//import Loading from "../common/loading.component";
//registerLocale("id", id);

export default function Lab() {
    let { mrn, id } = useParams();

    const [patient, setPatient] = useState();
    const [lab, setLab] = useState();
    const [notification, setNotification] = useState();

    const [indicators, setIndicators] = useState([]);
    const [qrCodeDataUri, setQRCodeDataUri] = useState();
    const [notes, setNotes] = useState([]);

    useEffect(() => {
        async function fetchData() {
            await axios
                .get(`${domain}/patients/` + mrn + "/" + id + "/lab")
                .then((response) => {
                    setPatient(response.data.patient);
                    setLab(response.data.lab);
                })
                .catch((error) => {
                    if (!error.response) {
                        // network error
                        setNotification({
                            message: "Exception: " + error,
                            type: "danger"
                        });
                    } else {
                        //setError(error.response.data.msg);
                        setNotification({
                            message: error.response.data.msg,
                            type: "danger"
                        });
                    }
                });
        }
        fetchData();
    }, [mrn, id]);

    useEffect(() => {
        function setData(data) {
            //setlab(data);
            var values = [];
            var indicators = [];
            for (var i in data.results) {
                values.push(data.results[i].value);
                indicators.push(data.results[i].indicator);
            }
            setIndicators(indicators);
            setNotes(getNotes(data));

            var delayInMilliseconds = 500; //1 second
            setTimeout(function () {
                //your code to be executed after 1 second
                setQRCodeDataUri(document.querySelector("canvas").toDataURL("image/jpg", 0.3));
            }, delayInMilliseconds);
        }

        if (lab) setData(lab);
    }, [lab]);

    const authorizedBy = () => {
        return lab.performedBy.indexOf("A.Md.A.K") > 0 || lab.performedBy.indexOf("A.Md.Kes") > 0 ? lab.performedBy : "Alfi Anshori, A.Md.A.K";
    };

    return (
        <div className="mt-3">
            {notification ? (
                <Alert message={notification.message} type={notification.type} />
            ) : patient && lab ? (
                <div>
                    <h5 className="text-center">Data Pemeriksaan Lab</h5>
                    <table className="table table-sm">
                        <tbody>
                            <tr>
                                <th>MRN</th>
                                <td>{patient.mrn}</td>
                            </tr>
                            <tr>
                                <th>Nama</th>
                                <td>{patient.fullName}</td>
                            </tr>
                            <tr>
                                <th>Tgl. Pemeriksaan</th>
                                <td>{moment(lab.completedAt).format("dddd, DD-MM-YYYY")}</td>
                            </tr>
                            <tr>
                                <th>Dokter</th>
                                <td>{lab.doctor ? lab.doctor : lab.requestedBy}</td>
                            </tr>
                            <tr>
                                <th>Jenis Pemeriksaan</th>
                                <td>
                                    {lab.category} - {lab.name}
                                </td>
                            </tr>
                            <tr style={{ visibility: "hidden", display: "none" }}>
                                <td colSpan={2}>
                                    <div id="canvas">
                                        <QRCode
                                            value={"https://archive.rscitrasundari.com/lab/" + patient.mrn + "/" + lab._id}
                                            size={95}
                                            qrStyle="dots"
                                            removeQrCodeBehindLogo={true}
                                            logoImage="/img/Logo.png"
                                            logoWidth={20}
                                        />
                                    </div>
                                </td>
                            </tr>
                            {qrCodeDataUri && (
                                <tr>
                                    <td colSpan={2} className="text-center">
                                        <LabResultPdf patient={patient} labResultData={lab} indicators={indicators} authorizedBy={authorizedBy()} notes={notes} qrImage={qrCodeDataUri} />
                                    </td>
                                </tr>
                            )}
                            {lab.attachmentUrl && (
                                <tr>
                                    <td colSpan={2} className="text-center">
                                        <a target="_blank" rel="noreferrer" href={lab.attachmentUrl} className="btn btn-sm btn-outline-secondary">
                                            Download Lampiran
                                        </a>
                                    </td>
                                </tr>
                            )}
                            <tr className="table-primary text-center">
                                <th colSpan={2}>Terverifikasi</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <Loading />
            )}
        </div>
    );
}
